import React, { memo } from 'react';
import type { ReactNode } from 'react';

import { StyleSheet, css } from 'aphrodite/no-important';

import { TextHeader } from '../common';
import SinglePlanItem from './SinglePlanItem';

import StyleGuide from '../../styles/StyleGuide';

type SinglePlanPresentationProps = {
  isGrouped?: boolean;
  onSelect: Function;
  engineId?: number;
  engine?: { id: number; label: string };
  plan: any;
};

const SinglePlanPresentation = ({
  isGrouped,
  onSelect,
  engineId,
  plan,
  engine,
}: SinglePlanPresentationProps): ReactNode => {
  const renderRow = (item: any, idx: number): ReactNode => (
    <SinglePlanItem
      type={plan.type}
      key={`${item.price}_${idx}`}
      item={item}
      onSelect={(item: Object): void => onSelect(plan, item)}
    />
  );

  console.log({ plan });

  const renderTable = (): ReactNode => (
    <div id='sg-component-wrapper'>
      <div className='c-table-wrapper c-table--wide'>
        <table cellSpacing='0'>
          <thead>
            <tr>
              {isGrouped && (
                <th className={css(styles.tableHeaderCell)}>
                  {'Pakiet:'.toUpperCase()}
                </th>
              )}
              <th className={css(styles.tableHeaderCell)}>
                {'Okres:'.toUpperCase()}
              </th>
              <th className={css(styles.tableHeaderCell)}>
                {(plan.type === 'engine' ? 'Pakiet:' : 'Limit:').toUpperCase()}
              </th>
              {plan.options[0]?.nadin !== undefined && (
                <th className={css(styles.tableHeaderCell)}>
                  {'Kod w NADIN:'.toUpperCase()}
                </th>
              )}
              {plan.options[0]?.price !== undefined && (
                <th className={css(styles.tableHeaderCell)}>
                  {'Cena pakietu (brutto):'.toUpperCase()}
                </th>
              )}
              {plan.options[0]?.installment !== undefined &&
                plan.options[0].installment !== '__INSTALLMENT__' && (
                  <th className={css(styles.tableHeaderCell)}>
                    {'Rata (brutto):'.toUpperCase()}
                  </th>
                )}
              <th
                className={`${css(styles.tableHeaderCell)} ${css(
                  styles.tableHeaderCellLast
                )}`}
              />
            </tr>
          </thead>
          <tbody>
            {!Boolean(engineId) &&
              plan.options.map(
                (item: Object, idx: number): ReactNode => renderRow(item, idx)
              )}
            {Boolean(engineId) &&
              plan.options
                .filter((item) => item.engineId === engineId)
                .map(
                  (item: Object, idx: number): ReactNode => renderRow(item, idx)
                )}
          </tbody>
        </table>
      </div>
      {plan.note && !Array.isArray(plan.note) && (
        <div className={css(styles.note)}>
          <span>{plan.note}</span>
        </div>
      )}
      {plan.note &&
        Array.isArray(plan.note) &&
        plan.note.map((item: Object, idx: number): ReactNode => {
          return (
            <div
              className={item.type === 'promo' ? 'u-h6' : css(styles.note)}
              key={idx}>
              <span>{item.text}</span>
            </div>
          );
        })}
    </div>
  );

  return (
    <div className={css(styles.container)}>
      <TextHeader className={css(styles.header)} type={'h3'}>
        {`${plan.name}`}
      </TextHeader>
      {engine && (
        <TextHeader className={css(styles.header2)} type={'h3'}>
          {`Pojemność silnika: ${engine.label}`}
        </TextHeader>
      )}
      {renderTable()}
    </div>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: '40px 0',
  },
  header: {
    paddingBottom: 0,
    textAlign: 'center',
  },
  header2: {
    paddingBottom: 5,
    textAlign: 'center',
  },
  tableHeaderCell: {
    borderTop: 'none !important',
    borderBottom: 'none !important',
    borderLeft: '1px solid black',
    height: 45,
    // fontSize: '16px !important',
    fontWeight: 400,
    padding: '0 0 0 10px !important',
    verticalAlign: 'baseline !important',
  },
  tableHeaderCellLast: {
    width: 65,
    borderLeft: 'none',
  },
  tableRowPromoted: {
    backgroundColor: StyleGuide.Colors.secondary,
  },
  note: {
    whiteSpace: 'break-spaces',
    paddingBottom: 10,
  },
});
export default memo(SinglePlanPresentation);
